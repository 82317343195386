
class LanguageStorage
{
    LANGUAGE_TOKEN_STORAGE_PATH: string = 'language_used';
    LANGUAGE: string;

    constructor() {
        this.LANGUAGE_TOKEN_STORAGE_PATH= 'language_used';
        const languages = ['de','en','fr'];
        let language: string = localStorage.getItem(this.LANGUAGE_TOKEN_STORAGE_PATH) || navigator.language || languages[0];
        if(!(languages.includes(language)))
        {
            language=languages[0];
        }
        this.LANGUAGE = language;
        localStorage.setItem(this.LANGUAGE_TOKEN_STORAGE_PATH,this.LANGUAGE);
    }

    setLanguage = (language: string) => {
        console.log("Switching to "+language);
        this.LANGUAGE = language;
        localStorage.setItem(this.LANGUAGE_TOKEN_STORAGE_PATH,this.LANGUAGE);
    }

    getLanguage= () => {
        return this.LANGUAGE;
    }

}
export default LanguageStorage;