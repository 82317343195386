import axios from 'axios';

class UserGateway
{
    static login(username,password,friendlycaptcha_solution, successCallback,errorCallback)
    {
        axios.post(process.env.REACT_APP_API_URL+'/auth/',
            {
                username: username,
                password: password,
                friendlycaptcha_solution: friendlycaptcha_solution
            }).then(successCallback).catch(errorCallback);
    }

    static register(username,password,friendlycaptcha_solution, successCallback,errorCallback)
    {
        axios.post(process.env.REACT_APP_API_URL+'/register/',{
            username: username,
            password: password,
            friendlycaptcha_solution: friendlycaptcha_solution
        }).then(successCallback).catch(errorCallback);
    }

    static getMe(successCallback,errorCallback)
    {
        axios.get(process.env.REACT_APP_API_URL+'/me/').then(successCallback).catch(errorCallback);
    }

    static changePassword(password,successCallback,errorCallback)
    {
        axios.patch(process.env.REACT_APP_API_URL+'/me/').then(successCallback).catch(errorCallback)
    }
}

export default UserGateway;