import React from 'react';
import {
    Container,
    Menu,
    Icon,
} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSignOutAlt} from "@fortawesome/pro-regular-svg-icons";
// import LanguageDropdown from "./FixedMenuLayout/LanguageDropdown";

class FixedMenuLayout extends React.Component {
    render() {
        return (
            <Menu fixed='top'>
                <Container>
                    <Menu.Item as={Link} to='/' header>
                        Spielebogen
                    </Menu.Item>
                    <Menu.Item as={Link} to='/mydata'>Meine Daten</Menu.Item>

                    <Menu.Menu position='right'>
                        {/*<LanguageDropdown/>*/}
                        <Menu.Item as={Link} to='/logoff'>
                            <Icon>
                                <FontAwesomeIcon fixedWidth icon={faSignOutAlt}/>
                            </Icon>
                            Abmelden
                        </Menu.Item>
                    </Menu.Menu>
                </Container>
            </Menu>
        );
    }
}

export default FixedMenuLayout;