import React, {Component} from 'react';
import UserGateway from "../Gateways/UserGateway";
import AuthStorage from "../Storages/AuthStorage";
import {WidgetInstance} from "friendly-challenge"
import { Segment, Message, Form, Grid, Header, Button} from "semantic-ui-react";
import {SessionContext} from "../Context/SessionContext";


class LoginPage extends Component {


    static contextType = SessionContext;

    FriendlyCaptchaRef = React.createRef();
    FriendlyCaptchaWidget = undefined;

    constructor(props)
    {
        super(props);
        this.state = {
            username : '',
            password: '',
            friendlycaptcha: undefined,
            loading: false,
            errorMessage: undefined,
        };
    }

    componentDidMount() {
        if(process.env.REACT_APP_FRIENDLYCAPTCHA_ENABLED)
        {
            const el = this.FriendlyCaptchaRef.current;
            this.FriendlyCaptchaWidget = new WidgetInstance(el,    {   doneCallback: (solution) => {   this.setState({friendlycaptcha: solution});
                    console.log("SOLUTION:\n"+solution);
                }   });
        }

    }

    handleChangeUsername = (event) => {
        this.setState({username: event.target.value});
    };

    handleChangePassword = (event) => {
        this.setState({password: event.target.value});
    };


    login = (event)=> {
        event.preventDefault();
        this.setState({loading: true});
        if(this.state.username.trim() === "")
        {
            this.setState({loading: false, errorMessage: "Enter a Username!"});
        }
        else if(this.state.password.trim() === "")
        {
            this.setState({loading: false, errorMessage: "Enter a Password!"});
        }
        else if(process.env.REACT_APP_FRIENDLYCAPTCHA_ENABLED && this.state.friendlycaptcha === undefined)
        {
            this.setState({loading: false, errorMessage: "Please Complete the Friendly Captcha"});
        }
        else
        {
            UserGateway.login(this.state.username,this.state.password,this.state.friendlycaptcha,this.handleLoginResponse,this.handleLoginError);
            if(process.env.REACT_APP_FRIENDLYCAPTCHA_ENABLED && this.FriendlyCaptchaWidget)
            {
                    this.FriendlyCaptchaWidget.reset();
            }

        }
    };

    handleLoginResponse = (response) => {
        if(response.data && response.data.sessionid)
        {
            AuthStorage.saveSessionKey(response.data.sessionid);
            this.setState({loading: false, successMessage: "Login Succeeded"});
            this.context.setValue(response.data.sessionid);
            // this.props.history.push("/");//redirect to main after authentication
            // window.location.reload();
        }
        else
        {
            this.setState({loading:false});
        }
    };

    handleLoginError = (error) => {
        if(error.response && error.response.status === 422 && error.response.data.error)
        {
            this.setState({loading: false, errorMessage: error.response.data.error });
        }
        else if(error.response && error.response.status === 400)
        {
            this.setState({loading: false, errorMessage: "Please Fill out Password / Username / Recaptcha"});
        }
        else if(error.status)
        {
            this.setState({loading:false, errorMessage: "Check Network Connection"});
        }
    };

    render()
    {

        const DivFriendlyCaptcha = process.env.REACT_APP_FRIENDLYCAPTCHA_ENABLED &&
            <div
                className={"frc-captcha"}
                ref={this.FriendlyCaptchaRef}
                data-sitekey={process.env.REACT_APP_FRIENDLYCAPTCHA_KEY}
                />;

        const DivErrorMessage = this.state.errorMessage &&
            <Message negative>{this.state.errorMessage} </Message>;

        const DivSuccessMessage = this.state.successMessage &&
            <Message positive>{this.state.successMessage}</Message>

        const DivAnyMessage = DivSuccessMessage || DivErrorMessage;

        return (
            <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
                <Grid.Column style={{ maxWidth: 450 }}>
                    <Header as='h2' color='black' textAlign='center'>
                    Spielebogen
                    </Header>
                    <Form size='large'>
                        <Segment stacked>
                            <Form.Input fluid icon='user' iconPosition='left' placeholder='Username' onChange={this.handleChangeUsername}  spellCheck="false"  />
                            <Form.Input fluid icon='lock' iconPosition='left' placeholder='Password' onChange={this.handleChangePassword}  type='password' />
                            {DivFriendlyCaptcha}
                            <Button color='black' fluid size='large' onClick={this.login}>
                                Login
                            </Button>
                        </Segment>
                    </Form>
                    {DivAnyMessage}
                </Grid.Column>
            </Grid>
        );
    };

}

export default LoginPage;
