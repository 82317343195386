import React, {Component} from 'react';
import UserGateway from "../Gateways/UserGateway";
import AuthStorage from "../Storages/AuthStorage";
import {Container, Header} from "semantic-ui-react";
import FixedMenuLayout from "../Layout/FixedMenuLayout";

class HomePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            errorMessage: undefined,
            me: undefined
        };
    }

    loadedMe = (response) => {
        if(response.status === 200)
        {
            this.setState({loading:false,me: response.data});
        }
    };

    errorLoading = (error) => {
        this.setState({loading: false, errorMessage: 'Could not Load Profile Data'});
        if(error.status === 401)
        {
            AuthStorage.removeSessionKey();
        }
    };

    componentDidMount() {
        UserGateway.getMe(this.loadedMe,this.errorLoading);
    }


    render() {
        return(
            <div>
                <FixedMenuLayout />
                <Container text style={{ marginTop: '7em' }}>
                    <Header as='h1'>Spielebogen</Header>

                        <p>Willkommen</p>

                </Container>
            </div>
        );
    }
}

export default HomePage;
